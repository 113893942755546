import store from '@/state/store';
import {Role} from "@/state/helpers"


export default [{
    path: '/login',
    name: 'login',
    component: () => import('../views/pages/account/login'),
    meta: {
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  

  {
    path: '/register',
    name: 'register',
    component: () => import('../views/pages/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot-password',
    component: () => import('../views/pages/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/pages/account/resetPassword'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({
            name: 'home'
          })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },

  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        
          store.dispatch('auth/logOut')
        
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? {
          name: 'home'
        } : {
          ...routeFrom
        })
      },
    },
  },
  {
    path: '/dashboard',
    name: 'super_admin_dashboard',
    meta: {
      authRequired: true,
      authorize:Role.SuperAdmin,
    },
    component: () => import('@/views/pages/super_admin/dashboard/index')
  },
  {
    path: '/restaurant-listing',
    name: 'restaurant_listing',
    meta: {
      authRequired: true,
      authorize:Role.SuperAdmin,
    },
    component: () => import('@/views/pages/super_admin/restaurant_owner/listing')
  },
  {
    path: '/create-restaurant',
    name: 'create_restaurant',
    meta: {
      authRequired: true,
      authorize:Role.SuperAdmin,
    },
    component: () => import('@/views/pages/super_admin/restaurant_owner/create')
  },
  {
    path: '/edit-profile-restaurant/:restaurant_id',
    name: 'edit_profile_restaurant',
    meta: {
      authRequired: true,
      authorize:Role.SuperAdmin,
    },
    component: () => import('@/views/pages/super_admin/restaurant_owner/edit')
  },

  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/dashboard/index')
  },
  {
    path: '/utility/starter',
    name: 'Starter-page',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/starter')
  },
  {
    path: '/utility/maintenance',
    name: 'maintenance',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/maintenance')
  },
  {
    path: '/utility/comingsoon',
    name: 'comingsoon',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/comingsoon')
  },
  {
    path: '/utility/timeline',
    name: 'timeline',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/timeline')
  },
  {
    path: '/utility/faqs',
    name: 'faqs',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/faqs')
  },
  {
    path: '/utility/pricing',
    name: 'pricing',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/pricing')
  },
  {
    path: '/utility/404',
    name: 'error-404',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/404')
  },
  {
    path: '/utility/500',
    name: 'error-500',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/utility/500')
  },
  
  {
    path: '/auth/login-1',
    name: 'login-1',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/auth/login-1')
  },
  {
    path: '/auth/register-1',
    name: 'register-1',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/auth/register-1')
  },{
    path: '/auth/lock-screen',
    name: 'lock-screen',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/auth/lock-screen')
  },{
    path: '/auth/recoverpwd',
    name: 'recoverpwd',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/auth/recoverpwd')
  },

  {
    path: '/calendar',
    name: 'calendar',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/calendar/calendar.vue')
  },

  {
    path: '/shifts-roster',
    name: 'shifts-roster',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/shiftsRoster.vue')
  },

  {
    path: '/tickets',
    name: 'tickets',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
      authorize_team_member: Role.TeamMember,
    },
    component: () => import('../views/pages/admin/ticketing/index.vue')
  },
  //shift Names Routes
  {
    path: '/shift-names',
    name: 'shift_names',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/shift-names/index')
  },
  {
    path: '/create-shift-name',
    name: 'create_shift_name',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/shift-names/create')
  },
  {
    path: '/edit-shift-name/:shiftName_id',
    name: 'edit_shift_name',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/shift-names/edit')
  },
  {
    path: '/employee-payroll',
    name: 'employee-payroll',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/employee-payroll/index.vue')
  },
  {
    path: '/single-employee/:employee_id',
    name: 'single-employee',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/employee-payroll/single-employee.vue')
  },

  {
    path: '/shifts-per-employee',
    name: 'shifts_per_employee',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/shifts-report/per-employee.vue')
  },

  {
    path: '/add-ticket',
    name: 'add-ticket',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
      authorize_team_member: Role.TeamMember,
    },
    component: () => import('../views/pages/admin/ticketing/add-ticket.vue')
  },
  {
    path: '/edit-ticket/:ticket_id',
    name: 'edit-ticket',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
      authorize_team_member: Role.TeamMember,
    },
    component: () => import('../views/pages/admin/ticketing/edit-ticket.vue')
  },
  {
    path: '/ticket-detail/:ticket_id',
    name: 'ticket-detail',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
      authorize_team_member: Role.TeamMember,
    },
    component: () => import('../views/pages/admin/ticketing/ticket-detail.vue')
  },

  // {
  //   path: '/feedbacks',
  //   name: 'feedbacks_listing',
  //   meta: {
  //     authRequired: true,
  //     authorize:Role.RestaurantOwner,
  //     authorize_manager:Role.Manager,
  //   },
  //   component: () => import('../views/pages/employee/feedback/feedbackListing')

  // },
  
  {
    path: '/notifications',
    name: 'notifications',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/notifications/notification')
  },
  // Manager  users
  {
    path: '/profile-manager',
    name: 'profile-manager',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/static/profile-manager')
  },
  {
    path: '/managers-listing',
    name: 'managers_listing',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      // authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/admin/manager/manager-listing')
  },
  {
    path: '/create-manager',
    name: 'create_manager',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      // authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/admin/manager/create')
  },
  {
    path: '/edit-profile-manager/:user_id',
    name: 'edit_profile_manager',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/admin/manager/edit')
  },

  // team members
  {
    path: '/team-member-requests',
    name: 'team_member_requests',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/team-members/team-member')
  },
  {
    path: '/team-member-record',
    name: 'team_member_record',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/team-members/all-record')
  },
  {
    path: '/former-team-member-record',
    name: 'former_team_member_record',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/team-members/former-member-listing')
  },
  {
    path: '/create-team-member',
    name: 'create_team_member',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/team-members/create')
  },
  {
    path: '/edit-profile-employee/:user_id',
    name: 'edit_profile_employee',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/admin/team-members/edit')
  },

  {
    path: '/team',
    name: 'team',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/static/team')
  },
  {
    path: '/create-shift',
    name: 'create-shift',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/create')
  },
  {
    path: '/create-weekly-shift',
    name: 'create_weekly_shift',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/createWeeklyShift')
  },
  {
    path: '/edit-clone-shift',
    name: 'edit_clone_shift',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/editClonedShifts')
  },
  {
    path: '/edit-shift/:shift_id',
    name: 'edit_shift',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/edit')
  },
  {
    path: '/shifts',
    name: 'shift_listing',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/shiftListing')
  },
  {
    path: '/business-owner-shift-listing',
    name: 'team_member_shift_listing',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/shift/team-members/listing')
  },
  {
    path: '/account/recoverpwd',
    name: 'recoverpwd',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/account/recoverpwd')
  },
  {
    path: '/account/forgot-password',
    name: 'Forgot Password',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/pages/account/forgot-password')
  },
  {
    path: '/my-profile',
    name: 'My Profile',
    meta: {
      authRequired: true,
      authorize: Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/static/my-profile')
  },
  {
    path: '/locations',
    name: 'Locations',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('../views/pages/admin/locations/locations')
  },
  {
    path: '/edit-location/:location_id',
    name: 'Edit_Location',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/admin/locations/edit-location')
  },
  {
    path: '/create-location',
    name: 'Create Location',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/admin/locations/create-location')
  },
  {
    path: '/workarea/listing',
    name: 'WorkArea Listing',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/admin/area/work-area-listing')
  },
  {
    path: '/workarea/create',
    name: 'Create WorkArea',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    
    component: () => import('@/views/pages/admin/area/create-work-area')
  },
  {
    path: '/area/edit/:area_id',
    name: 'Edit_Area',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    
    component: () => import('@/views/pages/admin/area/edit')
  },
  {
    path: '/wait-for-approval',
    name: 'Waiting',
    meta: {
      authRequired: false,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/account/thank-you')
  },
  // Team Member routes
  {
    path: '/verify-phone-number',
    name: 'verify-phone-number',
    meta: {
      authRequired: true,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
      authorize_team_member: Role.TeamMember,
    },
    props: true,
    component: () => import('@/views/pages/admin/team-members/components/validateOTP.vue')
  },

  {
    path: '/employee-home',
    name: 'employee_dashboard',
    meta: {
      authRequired: true,
      authorize:Role.TeamMember,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/employee/home')
  },
  {
    path: '/employee-profile',
    name: 'Employee Profile',
    meta: {
      authRequired: true,
      authorize:Role.TeamMember,
    },
    component: () => import('@/views/pages/employee/profile/my-profile')
  },
  {
    path: '/employee-schedule',
    name: 'employee_schedule',
    meta: {
      authRequired: true,
      authorize:Role.TeamMember,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/employee/schedule/schedules')
  },
  {
    path: '/employee/pick-released-shift/:shift_id',
    name: 'pick_released_shift',
    meta: {
      authRequired: true,
      authorize:Role.TeamMember,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/employee/pick-released-shift/pick-released-shift')
  },
  {
    path: '/shift-listing',
    name: 'shift_listing',
    meta: {
      authRequired: true,
      authorize:Role.TeamMember,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/employee/shift/listing')
  },
  {
    path: '/team-member-notifications',
    name: 'team_member_notifications',
    meta: {
      authRequired: true,
      authorize:Role.TeamMember,
    },
    component: () => import('@/views/pages/employee/notifications/notification')
  },
  // {
  //   path: '/feedback',
  //   name: 'feedback',
  //   meta: {
  //     authRequired: true,
  //     authorize:Role.TeamMember,
  //     authorize_manager:Role.Manager,
  //   },
  //   component: () => import('@/views/pages/employee/feedback/feedback')
  // },

  {
    path: '/settings',
    name: 'settings',
    meta: {
      authRequired: true,
      authorize:Role.TeamMember,
      authorize_manager:Role.Manager,
    },
    component: () => import('@/views/pages/employee/settings/setting')
  },
  // {
  //   path: '/*',
  //   name: 'Not-Found-page',
  //   meta: {
  //     authorize:Role.RestaurantOwner,
  //     authorize_manager:Role.Manager,
  //   },
  //   // component: () => import('@/views/pages/utility/404')
  // },

  {
    path: '/verify-token',
    name: 'verify.token',
    component: () => import('../views/pages/account/login-with-token'),
    meta: {
      authRequired: false,
      authorize:Role.RestaurantOwner,
      authorize_manager:Role.Manager,
    },
    
  },
]