import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
 

  ...mapGetters('auth', ['loggedIn']),
  
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const userMethods = mapActions('userModule', ['getuser','resetState'])
export const shiftMethods = mapActions('shift', ['resetArea','resetState','getShift'])
export const locationMethods = mapActions('locationModule', ['resetState'])
export const restaurantMethods = mapActions('restaurant', ['resetState'])

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'me','saveUser'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

// export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const Role = {
  SuperAdmin: 'super_admin',
  // RestaurantOwner: 'restaurant_owner',

  // as tech 
  // RestaurantOwner: 'admin',
  // TeamMember: 'technician',

//  as admin 
  RestaurantOwner: 'admin',
  TeamMember: 'technician',
  
  Manager: 'manager',
}